import React from 'react';

import dynamic from 'next/dynamic';

import { Loading } from '@components/molecules/loading';

const CSRJobs = dynamic(() => import('../../csrPages/jobs'), {
  loading: () => <Loading loading title="Loading" message="Building the page" />,
  ssr: false
});

const JobsPage = () => {
  return <CSRJobs />;
};

export default JobsPage;
