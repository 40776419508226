export async function getStaticProps() {
  return {
    props: {} // will be passed to the page component as props
  };
}

import React, { useEffect, useState, useRef } from 'react';

// import Image from 'next/image';

import { useInView } from 'react-intersection-observer';
import { mergeRefs } from 'react-merge-refs';
import dynamic from 'next/dynamic';

const Lottie = dynamic(() => import('lottie-react'), {
  loading: () => <p>Loading...</p>
});

import styles from './styles.module.scss';

// TODO: Add Sentry error capture.
const workerError = (err) => console.error('Worker Error: ', err);

const reportScroll =
  ({ wrapperRef, localActive, setLocalActiveState }) =>
    () => {
      if (wrapperRef.current.offsetHeight) {
        const cardHeight = wrapperRef.current.offsetHeight;
        const height = window.innerHeight;
        const activeArea = height - cardHeight;
        const rawFromTopOfViewPort =
        wrapperRef.current.getBoundingClientRect().top;
        const activeTrigger = rawFromTopOfViewPort < activeArea;

        if (activeTrigger !== localActive.current) {
          localActive.current = activeTrigger;
          setLocalActiveState(activeTrigger);
        }
      }
    };

export const LottieLoader = ({
  name,
  isActive = null,
  eager = false,
  loop = true,
  width = 300,
  height = 300
}) => {
  const [json, setJson] = useState(false);
  const localActive = useRef(false);
  const [localActiveState, setLocalActiveState] = useState(false);
  const wrapperRef = useRef(null);
  const lottieWorkerRef = useRef();
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0
  });

  useEffect(() => {
    const node = wrapperRef.current;

    if (node !== null && typeof window !== 'undefined') {
      const fn = reportScroll({
        wrapperRef,
        localActive,
        name,
        setLocalActiveState
      });

      window.addEventListener('scroll', fn, { passive: true });

      return () => {
        window.removeEventListener('scroll', fn);
      };
    }
  }, [wrapperRef, name]);

  useEffect(() => {
    if (typeof window !== 'undefined' && !lottieWorkerRef.current) {
      import('requestidlecallback-polyfill').then(() => {
        window.requestIdleCallback(
          () => {
            if (isActive) {
              (async () => {
                lottieWorkerRef.current = new Worker(
                  new URL('../../../workers/lottie.worker.js', import.meta.url)
                );

                lottieWorkerRef.current.addEventListener(
                  'message',
                  async (e) => {
                    if (e.data.name === name.replace(/lottie-|-img/g, '')) {
                      setJson({
                        Lottie,
                        data: e.data.data
                      });
                    }
                  }
                );

                lottieWorkerRef.current.onerror = workerError;
                lottieWorkerRef.current.onmessageerror = workerError;
                lottieWorkerRef.current.postMessage({
                  name: name.replace(/lottie-|-img/g, '')
                });
              })();
            } else {
              setJson(false);
            }
          },
          {
            timeout: 2000
          }
        );
      });
    }
  }, [isActive, localActive, localActiveState, name]);

  return (
    <span className={styles.lottieWrapper} ref={mergeRefs([ref, wrapperRef])}>
      {json && inView && (
        <div className={styles.lottieHolder}>
          <json.Lottie
            loop={loop}
            animationData={json.data}
            renderer={!eager ? 'svg' : 'canvas'}
            autoPlay={false}
          />
        </div>
      )}

      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        alt="loader"
        role="presentation"
        className={`${styles.imgHolder} ${json ? styles.hide : ''}`}
        src={`/lottie/images/${name}.png`}
        width={width}
        height={height}
      />
    </span>
  );
};

export default LottieLoader;
