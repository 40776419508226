import React, { useRef } from 'react';
import { Transition } from 'react-transition-group';

import { LottieLoader } from '@components/atoms/lottieLoader';
import { Container } from '@components/atoms/container';
import { Layout } from '@components/atoms/layout';

import styles from './styles.module.scss';

const duration = 200;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0
};

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 }
};

const Fade = ({ in: inProp, children }) => {
  const nodeRef = useRef(null);
  return (
    <Transition nodeRef={nodeRef} in={inProp} timeout={duration}>
      {(state) => (
        <div
          ref={nodeRef}
          style={{
            ...defaultStyle,
            ...transitionStyles[state]
          }}
        >
          {children}
        </div>
      )}
    </Transition>
  );
};

export const Loading = ({ children, loading, title, message }) => {
  if (loading) {
    return (
      <Layout needsLogin={false}>
        <Fade in appear>
          <Container centre>
            <div className={styles.loginWrapper}>
              <div className={styles.lottieWrapper}>
                <LottieLoader isActive={true} name="lottie-generic_loader_hammer-img" width={150} height={150} />
              </div>
              <h1 className={styles.loginTitle}>{title}</h1>
              <p className={styles.loadingText}>{message}</p>
            </div>
          </Container>
        </Fade>
      </Layout>
    );
  }

  return <>{children}</>;
};
